import React from "react";
import { LinkedInIcon, FacebookIcon, EmailIcon, GithubIcon, InstagramIcon } from "../components/IconsProvider";

export const contactInfoLists = [
    {
        id: 'Github',
        icon: <GithubIcon size={32} />,
        url: 'https://github.com/gabriel-caixeta'
    },
    {
        id: 'LinkedIn',
        icon: <LinkedInIcon size={32} />,
        url: 'https://linkedin.com/in/gabriel-caixeta-bonfim/'
    },
    {
        id: 'Facebook',
        icon: <FacebookIcon size={32} />,
        url: 'https://facebook.com/gabriel.caixeta.75/'
    },
    {
        id: 'Instagram',
        icon: <InstagramIcon size={32} />,
        url: 'https://instagram.com/gabrielcaixeta_/'
    },
    {
        id: 'Email',
        icon: <EmailIcon size={32} />,
        url: 'mailto:me@gabrielcaixeta.com'
    }
]