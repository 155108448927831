import styled from "styled-components";


export const FooterSection = styled.div`
    background: #515151;
    display: flex;
    justify-content: center;
    padding: 1vh;
    height: 5vh;

`;

export const WebsiteRights = styled.small`
    color: white;
    margin-bottom: 16px;
`;