import styled from "styled-components";

export const ContactContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 85vh;
    position: relative;
    z-index: 1;
`;

export const ContactBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: absolute;
`;

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 10px;
`;

export const ContactContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContactHeading = styled.h1`
    display: flex;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    text-decoration: underline;
    font-weight: bolder;
    padding: 20px 0;
    text-shadow: 2px 2px 2px #000;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480) {
        font-size: 32px;
    }

`;

export const ContactDescription = styled.h2`
    color: #fff;
    font-size: 24px;
    text-shadow: 2px 2px 1px #000;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480) {
        font-size: 18px;
    }

`;


export const ContactInfo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ContactIcon = styled.p`
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: gray;
    color: #fff;
`;