import styled from 'styled-components'
import {FaBars, FaGithub, FaLinkedin, FaFacebook, FaHiking, FaTerminal, FaTimes} from 'react-icons/fa'
import {IoMdCode, IoMdCodeWorking} from 'react-icons/io'
import {GoGraph} from 'react-icons/go'
import {GrInstagram} from 'react-icons/gr'

import {MdComputer} from 'react-icons/md'
import {HiOutlineMail} from 'react-icons/hi'

export const GcbLogo = styled(FaTerminal)`
    margin-left: 8px;
    font-size: 2rem;
`;

export const OpenSidebarIcon = styled(FaBars)``;

export const CloseSidebarIcon = styled(FaTimes)`
    color: #fff;
`;

export const CodeBlockClosed = styled(IoMdCode)`
    margin-left: 8px;
    font-size: 20px;
`;

export const CodeBlockOpen = styled(IoMdCodeWorking)`
    margin-left: 8px;
    font-size: 20px;
`;

export const LikesHike = FaHiking;

export const LikesData = GoGraph;

export const LikesWebdev = MdComputer;

export const LikesOpenSource = FaGithub;

export const GithubIcon = FaGithub;

export const LinkedInIcon = FaLinkedin;

export const FacebookIcon = FaFacebook;

export const InstagramIcon = GrInstagram;

export const EmailIcon = HiOutlineMail;

export const InlineGithubIcon = styled(FaGithub)`
    color: #fff;
    margin-right: 8px;
    font-size: 20px;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #010606;
        }
`;


