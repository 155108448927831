import React, { useState } from 'react'
import { ButtonS } from '../SharedElements'
import { CodeBlockClosed, CodeBlockOpen } from '../IconsProvider'
import { HeroBg, HeroBtnWrapper, HeroContainer, HeroContent, HeroH1, HeroP, ImageBg } from './HeroElements'

const Hero = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => { setHover(!hover)}
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */

    return (
        <HeroContainer id={"home"}>
            <HeroBg>
                <ImageBg alt='Test' src={require(`../../assets/images/hero_section-1.jpg`).default}/>
            </HeroBg>
            <HeroContent>
                <HeroH1 />
                <HeroH1 />
                <HeroH1>Gabriel Caixeta Bonfim</HeroH1>
                <HeroP />
                <HeroBtnWrapper>
                    <ButtonS to="likes" smooth={true} duration={500} spy={true} exact="true" offset={-80} fontSize={1} primary={1} onMouseEnter={onHover} onMouseLeave={onHover}>
                        Main {hover ? <CodeBlockOpen /> : <CodeBlockClosed />}
                    </ButtonS>

                </HeroBtnWrapper>
            </HeroContent>

        </HeroContainer>
    )
}

export default Hero
