import React from 'react'
import LikeItem from './LikeItem';
import {likesList} from '../../DataProviders/Likes'
import { SectionContainer, SectionTitle } from '../SharedElements';
import { LikesContainer } from './LikeItem/LikeElements';

function LikeItemsProvider() {
    const likes = likesList.map((item) =>
        <LikeItem {...item} key={item.id}/>
    );
    return ( <LikesContainer>{likes}</LikesContainer> );
}


const Likes = () => {
    return (
        <SectionContainer id={'likes'}>
            <SectionTitle>Likes</SectionTitle>
            <LikeItemsProvider />
        </SectionContainer>
    )
}

export default Likes
