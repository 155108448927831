import React, { Fragment } from 'react'
import { leftImgItem, rightImgItem } from '../../DataProviders/Projects';
import { SectionTitle } from '../SharedElements';
import ProjectItem from './ProjectItem';
import { ProjectsContainer } from './ProjectItem/ProjectElements';
import { ALL_FINAL_PROJECTS }from '../../api/client'
import { useQuery } from '@apollo/client'

function ProjectsProvider() {
    const { loading, error, data } = useQuery(ALL_FINAL_PROJECTS)

    if (loading) return <p>Loading...</p>
    if (error) {
        console.log(`API error: ${error}`);
        return <p>Error</p>
    }
    const projects = data.projects.map((project, index) => {
        if (index % 2 === 0) {
            return <ProjectItem {...project} {...rightImgItem} key={project._id}/>
        } else {
            return <ProjectItem {...project} {...leftImgItem} key={project._id}/>
        }
    }
    );

    return (
        <Fragment>{projects}</Fragment>
    );
}

const Projects = () => {
    return (
        <ProjectsContainer id={"projects"}>
            <SectionTitle>Projects</SectionTitle>
            <ProjectsProvider />
        </ProjectsContainer>
    )
}

export default Projects
