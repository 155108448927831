import styled from "styled-components";

export const LikesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
    background: #f9f9f9;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }

`;

export const LikeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
`;

export const LikeIcon = styled.div`
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 15px;
    background: #01bf71;
    color: #fff;
    box-shadow: 1px 1px 1px gray;
`;

export const LikeText = styled.p`
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
`;