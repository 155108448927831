import React, { useState, Fragment } from 'react'
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Hero from '../components/HeroSection';
import Likes from '../components/Likes';
import Navbar from '../components/Menu/Navbar'
import Sidebar from '../components/Menu/Sidebar'
import Projects from '../components/Projects';


const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {setIsOpen(!isOpen)};
    
    return (
        <Fragment>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle}/>
            <Hero />
            <Likes />
            <Projects />
            <Contact />
            <Footer />
        </Fragment>
    )
}

export default Home
