import {
    ApolloClient,
    InMemoryCache,
    // ApolloProvider,
    // useQuery,
    gql
} from "@apollo/client";

export const client = new ApolloClient({
    uri: 'https://projects.gabrielcaixeta.com/',
    cache: new InMemoryCache()
})

export const ALL_FINAL_PROJECTS = gql`
  query {
    projects {
      id
      title
      description
      category
      imageUrl
      projectUrl
      hasWebPage
      webUrl
    }
  }
`;

