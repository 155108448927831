import React from 'react';
import { LikesData, LikesHike, LikesOpenSource, LikesWebdev } from "../components/IconsProvider";

export const likesList = [
    {
        icon: <LikesOpenSource size={`2.5em`}/>,
        label: 'Open Source',
        id: "open-source"
    },
    {
        icon: <LikesWebdev size={`2.5em`}/>,
        label: 'Web Development',
        id: "web-dev"
    },
    {
        icon: <LikesData size={`2.5em`}/>,
        label: 'Data',
        id: "data"
    },
    {
        icon: <LikesHike size={`2.5em`}/>,
        label: 'Hiking',
        id: "hiking"
    }
]