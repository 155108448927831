import React from 'react'
import { CloseSidebarIcon } from '../../IconsProvider'
import { menuItems } from '../../../DataProviders/MenuItems'
import { Icon, SidebarContainer, SidebarLink, SidebarMenu, SidebarWrapper } from './SidebarElements'

function SidebarMenuProvider({ toggle }) {
    const items = menuItems.map(item =>
        <SidebarLink to={item.path} key={item.key}
            smooth={true} 
            duration={500} 
            spy={true} 
            exact='true' 
            offset={-80} 
            onClick={toggle}>
                {item.key}
        </SidebarLink>
        )
    return (
        <SidebarMenu>{items}</SidebarMenu>
    )

}

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseSidebarIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenuProvider toggle={toggle}/>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
