
export const projectsList = [
    {
        id: 'Project 1',
        
        category: 'Backend Project',
        title: 'GraphQL API',
        description: 'Python based GraphQL API using the Django framework',
        projectprojectUrl: `https://github.com/gabriel-caixeta`,

        buttonLabel: 'Check it out',
        img: require('../assets/images/server-backend.svg'),
        alt: 'Backend'
    },
    {
        id: 'Project 2',
        
        category: 'Frontend Project',
        title: 'Android App',
        description: 'Basic To-do List app for Android in Kotlin',
        projectUrl: `https://github.com/gabriel-caixeta`,

        buttonLabel: 'Check it out',
        img: require('../assets/images/frontend-android.svg'),
        alt: 'Frontend',
    },
    {
        id: 'Project 3',
        
        category: 'Algorithms',
        title: 'Path Finder Visualizer in Python',
        description: 'A tool for visualizing basic path finding algorithms, such as Dijkstra and A*, using Python',
        projectUrl: `https://github.com/gabriel-caixeta/path_finder`,

        buttonLabel: 'Check it out',
        img: require('../assets/images/algorithms.svg'),
        alt: 'Algorithm',
    },
    {
        id: 'Project 4',
        
        category: 'Algorithms',
        title: 'Sorting Visualizer in Python',
        description: 'A tool for visualizing the most common sorting algorithms using Python',
        projectUrl: `https://github.com/gabriel-caixeta`,

        buttonLabel: 'Check it out',
        img: require('../assets/images/algorithms.svg'),
        alt: 'Algorithm',
    }
]

export const leftImgItem = {
    imgStart: true,

    lightBg: false,
    headingLightText: true,
    descLightText: true
}

export const rightImgItem = {
    imgStart: false,

    lightBg: true,
    headingLightText: false,
    descLightText: false
}