import React, { useEffect, useState, Fragment } from 'react';
import { GcbLogo, OpenSidebarIcon } from '../../IconsProvider';
import { MobileIcon, Nav, NavbarContainer, NavItem, NavLinks, NavLogo, NavMenu } from './NavbarElements';
import {menuItems} from '../../../DataProviders/MenuItems'
import {animateScroll as scroll} from 'react-scroll'

function NavMenuProvider() {
    const navItemsList = menuItems.map((item) =>
        <NavItem key={item.key}>
            <NavLinks to={item.path} 
                smooth={true} 
                duration={500} 
                spy={true} 
                exact='true' 
                offset={-80}
                >
                    {item.key}
            </NavLinks>
        </NavItem>
    );
    return ( <NavMenu>{navItemsList}</NavMenu> );
}

const Navbar = ({ toggle }) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <Fragment>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={ toggleHome }>gcb<GcbLogo/></NavLogo>
                
                    <MobileIcon onClick={toggle}>
                        <OpenSidebarIcon />
                    </MobileIcon>

                    <NavMenuProvider />
    
                </NavbarContainer>

            </Nav>
        </Fragment>
    )
}

export default Navbar
