import React from 'react'
import { FooterSection, WebsiteRights } from './FooterElements'

const Footer = () => {
    return (
        <FooterSection>
            <WebsiteRights>Copyright © Gabriel Caixeta Bonfim {new Date().getFullYear()}</WebsiteRights>
        </FooterSection>
    )
}
export default Footer