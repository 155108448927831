import React from 'react'
import { contactInfoLists } from '../../DataProviders/ContactItems';
import { ContactBg, ContactContainer, ContactContent, ContactDescription, ContactHeading, ContactInfo, ImageBg } from './ContactElements';
import ContactItem from './ContactItem';

function ContactInfoProvider () {
    const contactLists = contactInfoLists.map((contactInfo) => 
        <ContactItem {...contactInfo} key={contactInfo.id}/>
        
    );
    return ( <ContactInfo>{contactLists}</ContactInfo> );
}

const Contact = (id) => {
    return (
        <ContactContainer id={"contact"}>
            <ContactBg>
                <ImageBg src={require(`../../assets/images/contact-1.jpg`).default} alt='Contact'/>
            </ContactBg>
            <ContactContent>
                <ContactHeading>Contact</ContactHeading>
                <ContactDescription>Feel free to contact me to discuss open source</ContactDescription>
                <ContactInfoProvider />
            </ContactContent>
        </ContactContainer>  
    )
}

export default Contact
