import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#01Bf71' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#01Bf71')};
        color: ${({dark}) => (dark ? '#fff' : '#010606')};
        }
`;

export const ButtonS = styled(LinkS)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#01Bf71' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#01Bf71')};
        color: ${({dark}) => (dark ? '#fff' : '#010606')};
        }
`;

export const SectionTitle = styled.p`
    display: flex;
    justify-content: center;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    background: #f9f9f9;
    padding-bottom: 20px;
`;

export const SectionContainer = styled.div`
    padding: 10px 0;
    background: #f9f9f9;
    min-height: 50vh;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
        padding: 10px 0;
    }
`;