import styled from "styled-components";

export const ProjectsContainer = styled.div`
    padding: 10px 0 0;
    background: #f9f9f9;
`;

export const ProjectContainer = styled.div`
    color: #fff;
    background: ${({lightBg}) => lightBg ? '#f9f9f9' : '#010606'};
    padding: 10px;

    @media screen and(max-width: 768px) {
        padding: 10px 0;
    }
`;

export const ProjectWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 860;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24;
    justify-content: center;
`;

export const ProjectRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: space-between;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    padding: 10px 30px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    padding: 10px 30px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 16px;
`;

export const Topline = styled.p`
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 24px;
    text-decoration: underline;
    font-weight: bolder;
    
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({headingLightText}) => (headingLightText ? '#f7f8f8' : '#010606')};

    @media screen and (max-width: 480) {
        font-size: 32px;
    }
`;

export const Description = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({descLightText}) => (descLightText ? '#fff' : '#010606')};
`;

export const BtnWrap = styled.div`
    /* justify-content: center; */
    display: flex;
    justify-content: space-evenly;
`;

export const ImgWrap = styled.div`
    padding: 15px 0;
    max-width: 555px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Img = styled.img`
    width: 80%;
`;
