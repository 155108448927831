import React from 'react'
import { ContactIcon } from './ContactElements'

const ContactItem = ({icon, url}) => {
    return (
            <a href={url}>
                <ContactIcon> 
                        {icon}
                </ContactIcon>
            </a>
    )
}

export default ContactItem
