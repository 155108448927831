import React, { Fragment } from 'react'
import { Button } from '../../SharedElements'
import { InlineGithubIcon } from '../../IconsProvider'
import { BtnWrap, Column1, Column2, Description, Heading, Img, ImgWrap, ProjectContainer, ProjectRow, ProjectWrapper, TextWrapper, Topline } from './ProjectElements'


const ProjectItem = ({lightBg, imgStart, category, title, headingLightText, description, descLightText, projectUrl, imageUrl, webUrl}) => {
    return (
        <Fragment>
            <ProjectContainer lightBg={lightBg}>
                <ProjectWrapper>
                    <ProjectRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <Topline>{category}</Topline>
                                <Heading headingLightText={headingLightText}>{title}</Heading>
                                <Description descLightText={descLightText}>{description}</Description>
                                <BtnWrap>
                                    {webUrl && (<Button primary={1} to={{pathname: webUrl}} target="_blank">Check it out</Button>)}
                                    {projectUrl && (<Button primary={0} to={{pathname: projectUrl}} target="_blank"><InlineGithubIcon /> Source code</Button>)}
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={imageUrl} className='test'/>
                            </ImgWrap>
                        </Column2>
                    </ProjectRow>
                </ProjectWrapper>
            </ProjectContainer>
        </Fragment>
    )
}


export default ProjectItem
