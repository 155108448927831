import React from 'react'
// import { LikesHike, WhiteGithubIcon } from '../../IconsProvider'
import { LikeContainer, LikeIcon, LikeText } from './LikeElements'

const LikeItem = ({icon, label}) => {
    return (
        <LikeContainer>
            <LikeIcon> {icon} </LikeIcon>
            <LikeText> {label} </LikeText>
            
        </LikeContainer>
    )
}

export default LikeItem
